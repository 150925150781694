// import React from "react";
// import '../CSS/Datenschutz.css';

// import {Route, Routes, Link} from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import NavFomos from './Nav-Fomos.js';
// import FooterFomos from './Footer-Fomos.js';

// const lngs = [
//     { code: "de", native: "Deutsch" },
//     { code: "en", native: "English" },
//   ];

// function DatenschutzFomos() {
    
//     const { t, i18n } = useTranslation();

//     const handleTrans = (code) => {
//         i18n.changeLanguage(code);
//       };
    
//     return (
//       <div>
//         <NavFomos />
//         <div>
            
//      <section id="title">
//       {/* Überschrift */}
//        <div class="title">
//         <i class="fa-regular fa-dash lineheader"></i>
//           <div class="titel-text">
//           <h1>{t("DatenschutzTitle")}</h1>
//           </div> 
//       </div>
//      </section>

//     <section id="datenschutz">
//     <div class="textbereich">
//      <div>
//                 <h3 class="texttitle"> {t("Datenschutz_S1_Title")}</h3>
//                 <p>{t("Datenschutz_S1_Text1")} </p> 
//                 <p>{t("Datenschutz_S1_Text2")} </p> 
//                 <p>{t("Datenschutz_S1_Text3")} </p> 
//     </div>
//     <br />
//     <br />
//     <div>
//                 <h3 class="texttitle"> {t("Datenschutz_S2_Title")}</h3>
//                 <p>{t("Datenschutz_S2_Text1")}</p> 
//                 <p>{t("Datenschutz_S2_Text2")} </p>
//                 <p>{t("Datenschutz_S2_Text3")} </p>
//     </div>
//     <br />
//     <br />
//     <div>
//                 <h3 class="texttitle"> {t("Datenschutz_S3_Title")}</h3>
//                 {t("Datenschutz_S3_Text1")} 
//                 <br />
//                 <br />
//        <ul> 
//                 <li class="LogFiles"> {t("Datenschutz_S3_ul1")}</li>
//                 <li class="LogFiles"> {t("Datenschutz_S3_ul2")}</li>
//                 <li class="LogFiles"> {t("Datenschutz_S3_ul3")}</li>
//                 <li class="LogFiles"> {t("Datenschutz_S3_ul4")}</li>
//                 <li class="LogFiles"> {t("Datenschutz_S3_ul5")}</li>
//                  </ul>
//     </div>
//     <br /> 
//     <div>
//                 <h3 class="texttitle"> {t("Datenschutz_S4_Title")}</h3>
//                 {t("Datenschutz_S4_Text")} 
//     </div>
//     </div>
//     </section>
//     <br />

//     <section id="impressum">
//     <div class="title">
//         <i class="fa-regular fa-dash lineheader"></i>
//           <div class="titel-text">
//           <h1>{t("ImpressumTitle")}</h1>
//           </div> 
//       </div>
//     <div class="textbereich_Impressum">

//       <p class="texttitle">  {t("Impressum_S1_Title")}</p>

//       <p class="adresse">
//       Rechtlicher Sitz: Karlsruher Institut für Technologie     <br />
//       Kaiserstr. 12     <br />
//       Deutschland     <br />
//       76131 Karlsruhe</p>

//       <br />
//       <p class="texttitle">  {t("Impressum_S2_Title")}</p>
//       <p> {t("Impressum_S2_Text1")} <a href= "tel: +49721608-0" >+49 721 608-0 </a> </p>
//       <p> {t("Impressum_S2_Text2")} <a href= "mailto: info@kit.edu" > info@kit.edu </a></p>
//       <p> Rechtsform: Körperschaft des öffentlichen Rechts</p>
//       <p> Vertretungsberechtigt: Prof. Dr.-Ing. Holger Hanselka (Präsident des KIT)</p>
//       <p> Umsatzsteueridentifikationsnummer: DE266749428</p>
      
//     </div>

//     </section>
//         </div>
//     <FooterFomos />
//     </div>
    
//     );
// }

// export default DatenschutzFomos;

// Import necessary modules and styles
import React from "react";
import '../CSS/Datenschutz.css';
import { Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavFomos from './Nav-Fomos.js';
import FooterFomos from './Footer-Fomos.js';

// Array of language options
const lngs = [
  { code: "de", native: "Deutsch" },
  { code: "en", native: "English" },
];

// Define the DatenschutzFomos component
function DatenschutzFomos() {

  // Use the translation hook to access translation functions
  const { t, i18n } = useTranslation();

  // Function to handle language change
  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  // Render the component
  return (
    <div>
      {/* Include the navigation component */}
      <NavFomos />
      <div>

        {/* Title section */}
        <section id="title">
          <div class="title">
            <i class="fa-regular fa-dash lineheader"></i>
            <div class="titel-text">
              <h1>{t("DatenschutzTitle")}</h1>
            </div>
          </div>
        </section>

        {/* Datenschutz section with various subsections */}
        <section id="datenschutz">
          <div class="textbereich">
            {/* Subsection 1 */}
            <div>
              <h3 class="texttitle"> {t("Datenschutz_S1_Title")}</h3>
              <p>{t("Datenschutz_S1_Text1")} </p>
              <p>{t("Datenschutz_S1_Text2")} </p>
              <p>{t("Datenschutz_S1_Text3")} </p>
            </div>
            <br />
            <br />

            {/* Subsection 2 */}
            <div>
              <h3 class="texttitle"> {t("Datenschutz_S2_Title")}</h3>
              <p>{t("Datenschutz_S2_Text1")}</p>
              <p>{t("Datenschutz_S2_Text2")} </p>
              <p>{t("Datenschutz_S2_Text3")} </p>
            </div>
            <br />
            <br />

            {/* Subsection 3 */}
            <div>
              <h3 class="texttitle"> {t("Datenschutz_S3_Title")}</h3>
              {t("Datenschutz_S3_Text1")}
              <br />
              <br />
              <ul>
                <li class="LogFiles"> {t("Datenschutz_S3_ul1")}</li>
                <li class="LogFiles"> {t("Datenschutz_S3_ul2")}</li>
                <li class="LogFiles"> {t("Datenschutz_S3_ul3")}</li>
                <li class="LogFiles"> {t("Datenschutz_S3_ul4")}</li>
                <li class="LogFiles"> {t("Datenschutz_S3_ul5")}</li>
              </ul>
            </div>
            <br />

            {/* Subsection 4 */}
            <div>
              <h3 class="texttitle"> {t("Datenschutz_S4_Title")}</h3>
              {t("Datenschutz_S4_Text")}
            </div>
          </div>
        </section>
        <br />

        {/* Impressum section */}
        <section id="impressum">
          <div class="title">
            <i class="fa-regular fa-dash lineheader"></i>
            <div class="titel-text">
              <h1>{t("ImpressumTitle")}</h1>
            </div>
          </div>
          <div class="textbereich_Impressum">

            {/* Subsection 1 */}
            <p class="texttitle">  {t("Impressum_S1_Title")}</p>

            {/* Address information */}
            <p class="adresse">
              Rechtlicher Sitz: Karlsruher Institut für Technologie     <br />
              Kaiserstr. 12     <br />
              Deutschland     <br />
              76131 Karlsruhe
            </p>

            <br />

            {/* Subsection 2 */}
            <p class="texttitle">  {t("Impressum_S2_Title")}</p>
            <p> {t("Impressum_S2_Text1")} <a href="tel: +49721608-0">+49 721 608-0 </a> </p>
            <p> {t("Impressum_S2_Text2")} <a href="mailto: info@kit.edu"> info@kit.edu </a></p>
            <p> Rechtsform: Körperschaft des öffentlichen Rechts</p>
            <p> Vertretungsberechtigt: Prof. Dr.-Ing. Holger Hanselka (Präsident des KIT)</p>
            <p> Umsatzsteueridentifikationsnummer: DE266749428</p>
          </div>
        </section>
      </div>

      {/* Include the footer component */}
      <FooterFomos />
    </div>
  );
}

// Export the DatenschutzFomos component
export default DatenschutzFomos;
