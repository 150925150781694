// Import necessary modules and components from React and external libraries
import React from "react";
import "../CSS/Fomos-main.css";
import { Route, Routes, Link } from "react-router-dom";
import { MethodenDivSimple } from "../input/Graph/GraphSimple/MethodenSimple";
import NavFomos from "./Nav-Fomos.js";
import FooterFomos from "./Footer-Fomos.js";
import { useData } from "../input/Graph/DataImport/useDataMethode.js";
import { useDataDatenstand } from "../input/Graph/DataImport/useDataDatenstand.js";
import { useTranslation } from "react-i18next";
import { TextBreiteSprache, InfoButtons } from "./DesignPrinciples";

// Define an array of language options
const lngs = [
  { code: "de", native: "Deutsch" },
  { code: "en", native: "English" },
];

// Get the current date and the day before yesterday as strings
const today = new Date();
const yesterday = new Date(today.getTime());
yesterday.setDate(today.getDate() - 1);
const yesterdayAsString = yesterday.toISOString().substring(0, 10);

const theDayBeforeYesterday = new Date(today.getTime());
theDayBeforeYesterday.setDate(today.getDate() - 2);
const theDayBeforeYesterdayAsString = theDayBeforeYesterday
  .toISOString()
  .substring(0, 10);

  // Define the Fomosexp component
function Fomosmain() {
    // Define a style object for navigation links

  const navStyle = {
    textDecoration: "none",
  };

    // Destructure the t (translation) and i18n (internationalization) objects from the useTranslation hook
  const { t, i18n } = useTranslation();

    // Function to handle language change

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

    // Fetch data using custom hooks
  const nocastArray = useData(
    "NowcastHub-MeanEnsemble",
    "00+",
    "DE",
    "FÜNFundNEUNZIG",
    "hunderttausend",
    theDayBeforeYesterdayAsString,
    yesterdayAsString
  );

  // Uncomment the line below if you want to log the nocastArray
  const unkorrHundArray = useDataDatenstand(
    "00+",
    "DE",
    "",
    "hunderttausend",
    theDayBeforeYesterdayAsString,
    yesterdayAsString
  );

  const unkorrAbsolArray = useDataDatenstand(
    "00+",
    "DE",
    "",
    "absoluteZahlen",
    theDayBeforeYesterdayAsString,
    yesterdayAsString
  );

  console.log(unkorrAbsolArray);
  console.log(nocastArray);
  console.log(unkorrHundArray);

    // Initialize variables for data values

  let nocastValueYesterday;
  let nocastValueTheDayBeforeYesterday;
  let unkorrHundValue;
  let unkorrAbsValue;

  // Check if data is still loading

  console.log(nocastArray);
  console.log(unkorrHundArray);
  console.log(unkorrAbsolArray);

  if ((!nocastArray, !unkorrHundArray, !unkorrAbsolArray)) {
    nocastValueYesterday = "Loading";
    nocastValueTheDayBeforeYesterday = "Loading";
    unkorrHundValue = "Loading";
    unkorrAbsValue = "Loading";
  } else {
    if (
      nocastArray.length != 0 &&
      unkorrHundArray.length  != 0 &&
      unkorrAbsolArray.length  != 0
    ) {
      nocastValueYesterday = Math.round(nocastArray[1].value * 10) / 10;
      nocastValueTheDayBeforeYesterday =
      Math.round(nocastArray[0].value * 10) / 10;
      unkorrHundValue = Math.round(unkorrHundArray[1].valueSieben * 10) / 10;
      unkorrAbsValue = Math.round(unkorrAbsolArray[1].valueSieben * 10) / 10;
    } else {
      nocastValueYesterday = "No Data uploaded";
      nocastValueTheDayBeforeYesterday = "No Data uploaded";
      unkorrHundValue = "No Data uploaded";
      unkorrAbsValue = "No Data uploaded";
    }
  }


    // Function to determine trend arrow based on data values

  function trendArrow() {
    if ((!nocastArray, !unkorrHundArray, !unkorrAbsolArray)) {
      return <p>Loading</p>;
    } else {
      if (
        nocastArray.length == 0 ||
        unkorrHundArray.length == 0 ||
        unkorrAbsolArray.length == 0
      ) {
        return <p>No Data uploaded</p>;
      } else {
        if (nocastValueYesterday > nocastValueTheDayBeforeYesterday) {
          return <p> &#8593; </p>; // pfeil oben
        } else if (nocastValueYesterday < nocastValueTheDayBeforeYesterday) {
          return <p> &darr; </p>; //pfeil unten
        } else if ((nocastValueYesterday = nocastValueTheDayBeforeYesterday)) {
          return <p> &rarr; </p>; //pfeil rechts
        }
      }
    }
  }



  return (
    <div>
      <NavFomos />
      <div>
        <section id="title">
          {/* Headline */}
          <div class="title">
            <i class="fa-regular fa-dash lineheader"></i>
            <div class="titel-text-main">
              <h1>NOWCAST</h1>
              <h2> {t("heading_nowcast")}</h2>
            </div>
          </div>
        </section>

        <section id="expbutton">
          <div className="expbutton">
            {/* Expert Mode Button */}
            <Link style={navStyle} to="/fomosexpert">
              <div class="button expbutton-expoff">
                <p class="text-expoff">{t("expertbutton_on")}</p>
              </div>
            </Link>
          </div>
        </section>

        <section id="entrance">
          {/* Text */}
          <section id="text">
            {TextBreiteSprache ? (
              <div className="text-introDP">
                <p>{t("text1_nowcastLeicht")}</p>
                <p>{t("text2_nowcastLeicht")}</p>
                <p>{t("text3_nowcastLeicht")}</p>
              </div>
            ) : (
              <div className="text-intro">
                <p>{t("text1_nowcast")}</p>
                <p>{t("text2_nowcast")}</p>
                <p>{t("text3_nowcast")}</p>
              </div>
            )}
          </section>

          {/* Buttons */}
          {InfoButtons ? (
            <div class="row rowbuttons">
              <div class="col-lg-3 col-md-6 col-sm-12">
                {/* merken */}
                <span class="hovertext" data-hover={t("button1hover_nowcast")}>
                  <div class="button infobutton">
                    {TextBreiteSprache ? (
                      <p class="text-fixDP">{t("button1_nowcastLeicht")}</p>
                    ) : (
                      <p class="text-fix">{t("button1_nowcast")}</p>
                    )}
                    <p class="text-var">{nocastValueYesterday}</p>
                  </div>
                </span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <span class="hovertext" data-hover={t("button2hover_nowcast")}>
                  <div class="button infobutton">
                    {TextBreiteSprache ? (
                      <p class="text-fixDP">{t("button2_nowcast")}</p>
                    ) : (
                      <p class="text-fix">{t("button2_nowcast")}</p>
                    )}

                    <p class="text-var"> {trendArrow()}</p>
                  </div>
                </span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 ">
                <span class="hovertext" data-hover={t("button3hover_nowcast")}>
                  <div class="button infobutton">
                    {TextBreiteSprache ? (
                      <p class="text-fixDP">{t("button3_nowcastLeicht")}</p>
                    ) : (
                      <p class="text-fix">{t("button3_nowcast")}</p>
                    )}

                    <p class="text-var">{unkorrHundValue}</p>
                  </div>
                </span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 ">
                <span class="hovertext" data-hover={t("button4hover_nowcast")}>
                  <div class="button infobutton">
                    {TextBreiteSprache ? (
                      <p class="text-fixDP">{t("button4_nowcast")}</p>
                    ) : (
                      <p class="text-fix">{t("button4_nowcast")}</p>
                    )}{" "}
                    <p class="text-var">{unkorrAbsValue}</p>
                  </div>
                </span>
              </div>
            </div>
          ) : (
            <div />
          )}
        </section>

        {/* Chart overall */}
        {/* Methode  */}

        <section id="chart">
          {/* Chart to be inserted*/}

          <MethodenDivSimple />
        </section>

        {/* Hinweis*/}

        <section id="hinweis">
          {TextBreiteSprache ? (
            <div class="text-hinweisDP">
              <p> {t("hinweisLeicht")} </p>
            </div>
          ) : (
            <div class="text-hinweis">
              <p> {t("hinweis")} </p>
            </div>
          )}
        </section>
      </div>
      <FooterFomos />
    </div>
  );
}

export default Fomosmain;
