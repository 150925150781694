import React, { useState, useEffect } from "react";
import { csv } from "d3";
import { getDateNow } from "./dateFinder.js";

let DataMethoden;

export function getDataMethoden() {
  return DataMethoden;
}

let DataDatenstand;

export function getDataDatenstand() {
  return DataDatenstand;
}

let DataDatenstandSchwarz;

export function getDataDatenstandSchwarz() {
  return DataDatenstandSchwarz;
}

export const setDataMethoden = async (date) => {
  const csvUrl =
    "https://raw.githubusercontent.com/KITmetricslab/hospitalization-nowcast-hub/main/nowcast_viz_de/plot_data/" +
    date +
    "_forecast_data.csv";

  csv(csvUrl).then((loadedData) => {
    DataMethoden = loadedData.map((d) => {
      delete d.pathogen;
      delete d.retrospective;
      delete d.target_type;
      return d;
    });
  });
};

// worker.postMessage("Hallo Worker!");
export const setDataDatenstand = (date) => {
  const csvUrl =
    "https://raw.githubusercontent.com/KITmetricslab/hospitalization-nowcast-hub/main/data-truth/COVID-19/COVID-19_hospitalizations_preprocessed.csv";

  let dateStart = new Date(date);
  dateStart.setDate(dateStart.getDate() - 35);
  dateStart = dateStart.toISOString().split("T")[0];

  csv(csvUrl).then((loadedData) => {
    DataDatenstand = loadedData.filter((d) => {
      return d.date >= dateStart;
      // return d.date <= date;
    });
  });

  console.log(DataDatenstand);
};
