// Importing necessary React hooks
import { useState, useEffect } from "react";

// Declaring a variable to hold temporary data outside the component
export let useTempDataDatenstand;

// Function to set temporary data
export function setuseTempDataDatenstand(data) {
  useTempDataDatenstand = data;
}

// Function to get temporary data
export function getuseTempDataDatenstand() {
  return useTempDataDatenstand;
}

// Custom hook for managing data using a web worker
export const useDataDatenstandWorker = (
  dataÜbergeben,
  menuAge,
  selectedScope,
  anzeige,
  dateGraphStart,
  dateEndÜbergeben
) => {
  // State to hold the processed data
  const [data, setData] = useState(null);

  // Variable to hold the web worker instance
  let dataOS;

  // Effect hook to handle the data processing with a web worker
  useEffect(() => {
    // Creating a new web worker instance
    const myWorker = new Worker(
      new URL("../DataImport/datenstandWorker.js", import.meta.url)
    );

    // Sending data to the web worker
    myWorker.postMessage({
      dataÜbergeben,
      menuAge,
      selectedScope,
      anzeige,
      dateGraphStart,
      dateEndÜbergeben,
    });

    // Event listener for receiving data from the web worker
    myWorker.onmessage = (event) => {
      // Extracting data from the event
      const receivedData = event.data;

      // Updating the state with the received data
      setData(receivedData);

      // Terminating the web worker to free up resources
      myWorker.terminate();
    };
  }, [
    // Dependency array to re-run the effect when any of these values change
    dataÜbergeben,
    menuAge,
    selectedScope,
    anzeige,
    dateGraphStart,
    dateEndÜbergeben,
  ]);

  // Returning the processed data
  return data;
};
