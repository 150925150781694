import React, { useState, useEffect } from "react";
import { Graph } from "./Graph";
import { Dropdown } from "../Zusatz/Dropdown";
import {
  optionsAge,
  optionsBundesland,
  initialValueAge,
  initialValue,
  initialdateGraphStart,
} from "../Zusatz/optionsCollection";
import "./StyleLinks.css";
import "./StyleMethoden.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDataDatenstand } from "../DataImport/useDataDatenstand";
import { DiscreteColorLegend } from "react-vis";
import "react-vis/dist/style.css";
import { withResizeDetector } from "react-resize-detector";
import { useTranslation } from "react-i18next";
import { init } from "i18next";
import {useDataMethodeWorker} from "../DataImport/methodenWorkerCall";
import { useDataDatenstandWorker } from "../DataImport/datenstandWorkerCall";
import {
  setDataMethoden,
  getDataMethoden,
  getDataDatenstand,
  setDataDatenstand,
  // getDataDatenstandSchwarz,
} from "../DataImport/importDataMethoden.js";
import { getDateNow, setDateStart } from "../DataImport/dateFinder";

const lngs = [
  { code: "de", native: "Deutsch" },
  { code: "en", native: "English" },
];

// Here is the setup for the graph and the selection section on the left side of the window ----------------------------------------------------------

export const MethodenDivSimple = () => {
  const [dateGraphStart, setGraphDateStart] = useState(initialdateGraphStart);
  

  //Hide and Show the selection section for methods ----------------------------------------------------------
  const [label, setLabel] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  function handleClickAMethoden() {
    setIsVisible(!isVisible);
    setLabel(isVisible ? "" : "");
  }

  // const for the selection section on the left side of the window ----------------------------------------------------------

  const [menuAge, setmenuAge] = useState(initialValueAge);
  const [anzeige, setAnzeige] = useState("hunderttausend");
  const [selectedScope, setScope] = useState(initialValue);
  const [date, setDate] = useState(getDateNow()); //
  const [intervall, setIntervall] = useState("FÜNFundNEUNZIG");

  if (!getDataMethoden()) {
    setDateStart(getDateNow());
    // setDataMethoden(getDateNow());
  }

  if (!getDataDatenstand()) {
    setDateStart(getDateNow());
  }



  let dataMethode = getDataMethoden(date);

  let data = useDataMethodeWorker(
    dataMethode,
    menuAge,
    selectedScope,
    intervall,
    anzeige,
    "2023-07-17",
    "2023-09-20"
  );

  let dataDatenstandBF = getDataDatenstand();



  

  let dataDatenstandWorker = useDataDatenstandWorker(
    dataDatenstandBF,
    menuAge,
    selectedScope,
    anzeige,
    "2023-07-17",
    getDateNow()
  );



  let dataDatenstandSchwarzWorker = useDataDatenstandWorker(
    dataDatenstandBF,
    menuAge,
    selectedScope,
    anzeige,
    "2023-07-17",
    date
  );

  if(!dataDatenstandSchwarzWorker){
    dataDatenstandSchwarzWorker = dataDatenstandWorker;
  }



  // let dataDatenstandGrauWorker = useDataDatenstandWorker(
  //   dataDatenstandBF,
  //   menuAge,
  //   selectedScope,
  //   anzeige,
  //   "2023-07-17",
  //   date
  // );

  console.log(dataDatenstandWorker);
  console.log(dataDatenstandSchwarzWorker);
  console.log(getDateNow());
  console.log(date);

  const datenstand_schwarz = useDataDatenstand(
    menuAge,
    selectedScope,
    intervall,
    anzeige,
    "2023-07-17",
    "2023-08-08"
  );

  const { t, i18n } = useTranslation();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  const [isDatenstandSchwarz, setDatenstandSchwarz] = useState(true);
  const [isDatenstandGrau, setDatenstandGrau] = useState(true);
  const [isEpiforecast, setEpiforecast] = useState(false);
  const [isILM, setILM] = useState(false);
  const [isKIT, setKIT] = useState(false);
  const [isLMU, setLMU] = useState(false);
  const [isNowcast, setNowcast] = useState(true);
  const [isNowcastMedian, setNowcastMedian] = useState(false);
  const [isRIVM, setRIVM] = useState(false);
  const [isRKI, setRKI] = useState(false);
  const [isSU, setSU] = useState(false);
  const [isSZ, setSZ] = useState(false);

  const [isDatenstandSchwarzMarkiert, setDatenstandSchwarzMarkiert] =
    useState(false);
  const [isDatenstandGrauMarkiert, setDatenstandGrauMarkiert] = useState(false);
  const [isEpiforecastMarkiert, setEpiforecastMarkiert] = useState(false);
  const [isILMMarkiert, setILMMarkiert] = useState(false);
  const [isKITMarkiert, setKITMarkiert] = useState(false);
  const [isLMUMarkiert, setLMUMarkiert] = useState(false);
  const [isNowcastMarkiert, setNowcastMarkiert] = useState(false);
  const [isNowcastMedianMarkiert, setNowcastMedianMarkiert] = useState(false);
  const [isRIVMMarkiert, setRIVMMarkiert] = useState(false);
  const [isRKIMarkiert, setRKIMarkiert] = useState(false);
  const [isSUMarkiert, setSUMarkiert] = useState(false);
  const [isSZMarkiert, setSZMarkiert] = useState(false);

  const ResponsiveXYPlot = withResizeDetector(({ width, height }) => (
    <Graph
    width={width}
      className="graph"
      isVisible={isVisible}
      isDatenstandSchwarz={isDatenstandSchwarz}
      isDatenstandGrau={isDatenstandGrau}
      isEpiforecast={isEpiforecast}
      isILM={isILM}
      isKIT={isKIT}
      isLMU={isLMU}
      isNowcast={isNowcast}
      isNowcastMedian={isNowcastMedian}
      isRIVM={isRIVM}
      isRKI={isRKI}
      isSU={isSU}
      isSZ={isSZ}
      datenstand_schwarz={datenstand_schwarz}
      isDatenstandSchwarzMarkiert={isDatenstandSchwarzMarkiert}
      isDatenstandGrauMarkiert={isDatenstandGrauMarkiert}
      isEpiforecastMarkiert={isEpiforecastMarkiert}
      isILMMarkiert={isILMMarkiert}
      isLMUMarkiert={isLMUMarkiert}
      isKITMarkiert={isKITMarkiert}
      isNowcastMarkiert={isNowcastMarkiert}
      isNowcastMedianMarkiert={isNowcastMedianMarkiert}
      isRKIMarkiert={isRKIMarkiert}
      isSUMarkiert={isSUMarkiert}
      isSZMarkiert={isSZMarkiert}
      isRIVMMarkiert={isRIVMMarkiert}
      dateGraphStart={dateGraphStart}
      height={150}
      width={850}
      intervall={intervall}
      DataMethoden={getDataMethoden()}
      menuAge={menuAge}
      selectedScope={selectedScope}
      anzeige={anzeige}
      date={date}
      data={data}
      dataDatenstandWorker={dataDatenstandWorker}
      dataDatenstandSchwarzWorker={dataDatenstandSchwarzWorker}
    />
  ));

  const buttonClassNameSchwarz = isDatenstandSchwarz
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameGrau = isDatenstandGrau
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameEpi = isEpiforecast
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameILM = isILM
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameKIT = isKIT
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameLMU = isLMU
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameNowcast = isNowcast
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameNowcastMedian = isNowcastMedian
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameRIVM = isRIVM
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameRKI = isRKI
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameSU = isSU
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";
  const buttonClassNameSZ = isSZ
    ? "btn buttonNmarkiert rounded"
    : "btn buttonmarkiert rounded";

    console.log(isKIT);

  function DatenstandSchwarz() {
    const handleButtonClick = () => {
      setDatenstandSchwarz(!isDatenstandSchwarz);
    };

    const handleMouserOverFalse = () => {
      setDatenstandSchwarzMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setDatenstandSchwarzMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameSchwarz}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            Datenstand Schwarz
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover1_1_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }

  function DatenstandGrau() {
    const handleButtonClick = () => {
      setDatenstandGrau(!isDatenstandGrau);
    };
    const handleMouserOverFalse = () => {
      setDatenstandGrauMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setDatenstandGrauMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameGrau}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            Datenstand Grau
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover1_2_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }

  function DatenEpiforcecast() {
    const handleButtonClick = () => {
      setEpiforecast(!isEpiforecast);
    };
    const handleMouserOverFalse = () => {
      setEpiforecastMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setEpiforecastMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameEpi}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            independet Epicforecast
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover2_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }

  function DatenILM() {
    const handleButtonClick = () => {
      setILM(!isILM);
    };
    const handleMouserOverFalse = () => {
      setILMMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setILMMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameILM}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>ILM prop</div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover3_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenKITSimple() {
    const handleButtonClick = () => {
      setKIT(!isKIT);
    };
    const handleMouserOverFalse = () => {
      setKITMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setKITMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameKIT}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            KIT Simple Nowcast
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover4_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenLMU() {
    const handleButtonClick = () => {
      setLMU(!isLMU);
    };
    const handleMouserOverFalse = () => {
      setLMUMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setLMUMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameLMU}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "91%", justifyContent: "center" }}>
            LMU StaBlabGAM Nowcast
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover5_1_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenNowcast() {
    const handleButtonClick = () => {
      setNowcast(!isNowcast);
    };
    const handleMouserOverFalse = () => {
      setNowcastMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setNowcastMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameNowcast}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            Nowcast HUB Mean Ensemple
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover5_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenNowcastMedian() {
    const handleButtonClick = () => {
      setNowcastMedian(!isNowcastMedian);
    };
    const handleMouserOverFalse = () => {
      setNowcastMedianMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setNowcastMedianMarkiert(true);
    };
    return (
      <button 
        className={buttonClassNameNowcastMedian}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            Nowcast HUB Median Ensemple
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover6_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenRIVM() {
    const handleButtonClick = () => {
      setRIVM(!isRIVM);
    };
    const handleMouserOverFalse = () => {
      setRIVMMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setRIVMMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameRIVM}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            RIVM Weekly Report
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover7_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenRKI() {
    const handleButtonClick = () => {
      setRKI(!isRKI);
    };
    const handleMouserOverFalse = () => {
      setRKIMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setRKIMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameRKI}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            RKI Weekly Report
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover8_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenSU() {
    const handleButtonClick = () => {
      setSU(!isSU);
    };
    const handleMouserOverFalse = () => {
      setSUMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setSUMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameSU}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            SU hier bayes
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover9_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function DatenSZ() {
    const handleButtonClick = () => {
      setSZ(!isSZ);
    };
    const handleMouserOverFalse = () => {
      setSZMarkiert(false);
    };
    const handleMouserOverTrue = () => {
      setSZMarkiert(true);
    };
    return (
      <button
        className={buttonClassNameSZ}
        onClick={handleButtonClick}
        onMouseEnter={handleMouserOverTrue}
        onMouseLeave={handleMouserOverFalse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            SZ Nowcast
          </div>
          <div
            style={{ width: "5%" }}
            className="hovertextM hoverq"
            data-hover={t("hover10_methoden")}
          >
            <p> ⓘ</p>
          </div>
        </div>
      </button>
    );
  }
  function ZusatzStyling() {
    return (
      <button style={{ opacity: 0, visibility: "hidden" }}>
        <div>
          <div style={{ width: "95%", justifyContent: "center" }}>
            {" "}
            SZ Nowcast
          </div>
        </div>
      </button>
    );
  }


  function DatenstandEnde() {
    const handleDateChange = (selectedDate) => {
      const currentDate = new Date(selectedDate);
      const graphStartDate = new Date(
        currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
      );

      setDate(selectedDate);
      setGraphDateStart(graphStartDate.toISOString().split("T")[0]);

      setDataDatenstand(date);
      setDataMethoden(date);

    };

    return (
      <div id="unsicherheitsintervall" className="menuOptionen">
      {getDateNow() && (
        <label className="einführung">
          <b>{"Datenstand"}</b>
          <button className="btn btn-light rounded btn-date" onClick={() => {}}>
            <input
              type="date"
              name="select-date"
              value={date}
              onChange={(e) => {handleDateChange(e.target.value); setDataMethoden(e.target.value); setDataDatenstand(e.target.value);}}
              max={getDateNow()}
              min="2021-07-01"
            />
          </button>
        </label>
      )}
      </div>
    );
  }

  const [selectedFilter, setSelectedFilter] = useState("Bundesland");

  function Filter() {
    const handleBunesland = () => {
      setSelectedFilter("Bundesland");
    };

    const handleAlter = () => {
      setSelectedFilter("Alter");
    };

    return (
      <div id="filter" className="menuOptionen">
        <label className="einführung">
          <b>{"Filter"}</b>
        </label>
        <div id="inhalt">
          <div>
            <span
              onMouseEnter={handleBunesland}
              onClick={handleBunesland}
              className={selectedFilter === "Bundesland" ? "active" : ""}
            >
              {t("Bundesland")}
            </span>{" "}
            |{" "}
            <span
              onMouseEnter={handleAlter}
              onClick={handleAlter}
              className={selectedFilter === "Alter" ? "active" : ""}
            >
              {t("Alter")}
            </span>
          </div>
          {selectedFilter === "Bundesland" && (
            <div id="div1">
              <button className="btn btn-light button-filter rounded">
                <Dropdown
                  options={optionsBundesland}
                  id="age-select"
                  selectedValue={selectedScope}
                  onSelectedValueChange={(selectedValue) => {
                    setmenuAge("00+");
                    setScope(selectedValue);
                  }}
                />
              </button>
            </div>
          )}

          {selectedFilter === "Alter" && (
            <div id="div2">
              <button className="btn btn-light button-filter rounded ">
                <Dropdown
                  options={optionsAge}
                  id="age-select"
                  selectedValue={menuAge}
                  onSelectedValueChange={(selectedValue) => {
                    setmenuAge(selectedValue);
                    setScope("DE");
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  function Anzeige() {
    return (
      <div id="anzeige" className="menuOptionen">
        <label className="einführung">
          <b>{t("Anzeige")}</b>
        </label>
        <div id="inhalt">
          <button
            className="btn btn-light button-anzeige rounded"
            onClick={() => setAnzeige("hunderttausend")}
          >
            <label>{t("per100k")}</label>
            <input
              type="radio"
              name="größe"
              value="hunderttausend"
              checked={anzeige === "hunderttausend"}
            />
          </button>
          <button
            className="btn btn-light button-anzeige rounded"
            onClick={() => setAnzeige("absoluteZahlen")}
          >
            <label>{t("absoluteZ")}</label>
            <input
              type="radio"
              name="größe"
              value="absoluteZahlen"
              checked={anzeige === "absoluteZahlen"}
            />
          </button>
        </div>
      </div>
    );
  }

  function Unsicherheitsintervall() {
    return (
      <div id="unsicherheitsintervall" className="menuOptionen">
        <label className="einführung">
          <b>{"Unsicherheitsintervall"}</b>
        </label>
        <div id="inhalt">
          <button
            className="btn btn-light button-unsicherheitsintervall rounded"
            onClick={() => setIntervall("FÜNFundNEUNZIG")}
          >
            <label className="usi">95%</label>
            <input
              type="radio"
              name="unsicherheitsintervall"
              value="FÜNFundNEUNZIG"
              checked={intervall === "FÜNFundNEUNZIG"}
            />
          </button>
          <button
            className="btn btn-light button-unsicherheitsintervall rounded"
            onClick={() => setIntervall("FÜNFZIG")}
          >
            <label className="usi">50%</label>
            <input
              type="radio"
              name="unsicherheitsintervall"
              value="FÜNFZIG"
              checked={intervall === "FÜNFZIG"}
            />
          </button>

          <button
            className="btn btn-light button-unsicherheitsintervall rounded"
            onClick={() => setIntervall("keines")}
          >
            {/* <label className="usi">{("keines")}</label> */}
            <label className="usi">{"keines"}</label>
            <input
              type="radio"
              name="unsicherheitsintervall"
              value="keines"
              checked={intervall === "keines"}
            />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
      <DiscreteColorLegend
        width="15%"
        scroll={false}
        items={[
          // { title: <DatenstandStart />, color: "none" },
          { title: <DatenstandEnde />, color: "none" },
          { title: <Filter />, color: "none" },
          { title: <Anzeige />, color: "none" },
          { title: <Unsicherheitsintervall />, color: "none" },
        ]}
      />
        <ResponsiveXYPlot />
   
 
      <DiscreteColorLegend
 
        width="13%"
        height={650}
        items={[
          { title: <DatenstandSchwarz />, color: "black" },
          { title: <DatenstandGrau />, color: "grey" },
          // { title: <DatenEpiforcecast />, color: "#323200" },
          // { title: <DatenKITSimple />, color: "#FF643280" },
          // { title: <DatenILM />, color: "#00640080" },
          // { title: <DatenLMU />, color: "#00FFFF80" },
          { title: <DatenNowcast />, color: "#32320080" },
          // { title: <DatenNowcastMedian />, color: "#0A320080" },
          // { title: <DatenRIVM />, color: "#80008080" },
          // { title: <DatenRKI />, color: "#646464FF" },
          // { title: <DatenSU />, color: "#00006433" },
          // { title: <DatenSZ />, color: "#4B008280" },
          // { title: <ZusatzStyling />, color: "none" },
          // { title: <ZusatzStyling />, color: "none" },
          // { title: <ZusatzStyling />, color: "none" },
          // { title: <ZusatzStyling />, color: "none" },
          // { title: <ZusatzStyling />, color: "none" },
        ]}
      />
    </div>
  );
};
