import { useState, useEffect } from "react";
export let useTempDataDatenstand;

export let useTempdata;

export function setTempData() {}

export function getTempData() {
  return useTempdata;
}

//Here the worker gets the data from worker file (workerMethoden.js)

export const useDataMethodeWorker = (
  dataÜbergeben,
  menuAge,
  selectedScope,
  intervall,
  anzeige,
  dateGraphStart,
  date
) => {
  const [data, setData] = useState(null);

  console.log(dataÜbergeben);

  useEffect(() => {
    const myWorker = new Worker(
      new URL("./workerMethoden.js", import.meta.url)
    );

    myWorker.postMessage({
      dataÜbergeben,
      menuAge,
      selectedScope,
      intervall,
      anzeige,
      dateGraphStart,
      date,
    });

    myWorker.onmessage = (event) => {
      const receivedData = event.data;
      setData(receivedData);
      myWorker.terminate();
    };
  }, [
    dataÜbergeben,
    menuAge,
    selectedScope,
    intervall,
    anzeige,
    dateGraphStart,
    date,
  ]);

  if (data) {
    setTempData(data);
  }

  return data;
};
