import {
  setDataDatenstand,
  setDataMethoden,
  setDataDatenstandSchwarz,
} from "./importDataMethoden";

let dateNow = null;

export async function setDateStart() {
  dateNow = await getWorkingLink();

  setDataMethoden(dateNow);
  setDataDatenstand(dateNow);
}

export function setDateNow(newDate) {
  dateNow = newDate;
}

export function getDateNow() {
  return dateNow;
}

// Funktion, um den funktionierenden Link zu erhalten
async function getWorkingLink() {
  let currentDate = new Date();

  let dateMethoden;

  while (true) {
    dateMethoden = await checkLinkExists(
      currentDate.toISOString().slice(0, 10)
    );

    // console.log(dateMethoden);

    if (dateMethoden != null) {
      break;
    } else {
      // Einen Tag zurückgehen, wenn der Link nicht existiert
      currentDate.setDate(currentDate.getDate() - 1);
    }
  }

  return currentDate.toISOString().slice(0, 10);
}

function findDateRealData() {
  let date;

  return date;
}

async function checkLinkExists(date) {
  const csvUrl =
    "https://raw.githubusercontent.com/KITmetricslab/hospitalization-nowcast-hub/main/nowcast_viz_de/plot_data/" +
    date +
    "_forecast_data.csv";
  // const csvUrl = "public/Methoden/" + date + "_forecast_data.csv";

  try {
    const response = await fetch(csvUrl);

    if (response.status === 200) {
      const text = await response.text();
      if (text.trim() !== "") {
        return date; // Der Link existiert und enthält Inhalte
      } else {
        // console.log("Link exists but has no content");
        return null; // Der Link existiert, enthält jedoch keine Inhalte
      }
    } else {
      // console.log("Link does not exist or is not reachable");
      return null; // Der Link existiert nicht oder ist nicht erreichbar
    }
  } catch (error) {
    console.error("Error:", error);
    return null; // Bei einem Fehler wird ebenfalls 'null' zurückgegeben
  }
}
