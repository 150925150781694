import React, { useEffect, useState, useCallback } from "react";
import { timeFormat } from "d3";
import { useTranslation } from "react-i18next";
import { timeParse } from "d3";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  DiscreteColorLegend,
  SearchableDiscreteColorLegend,
  GradientDefs,
  FlexibleWidthXYPlot,
  Hint,
} from "react-vis";
import "./StyleGraph.css";
import "react-vis/dist/style.css";
const lngs = [
  { code: "de", native: "Deutsch" },
  { code: "en", native: "English" },
];

// Here is the Graph for the Simple Mode, which will be used in GraphuUmgebung.js

export const Graph = ({
  isDatenstandSchwarz,
  isDatenstandGrau,
  isEpiforecast,
  isILM,
  isKIT,
  isLMU,
  isNowcast,
  isNowcastMedian,
  isRIVM,
  isRKI,
  isSU,
  isSZ,
  intervall,
  isEpiforecastMarkiert,
  isILMMarkiert,
  isKITMarkiert,
  isLMUMarkiert,
  isNowcastMarkiert,
  isNowcastMedianMarkiert,
  isRIVMMarkiert,
  isRKIMarkiert,
  isSUMarkiert,
  isSZMarkiert,
  data,
  dataDatenstandWorker,
  dataDatenstandSchwarzWorker,
  anzeige,
  date,
  menuAge,
  selectedScope,
  // dataILM,
  // dataSU,
  // dataKIT,
  // dataMeanEnsemble,
  // dataMedianEnsemble,
  // dataLMU,
  // dataRIVM,
  // dataRKI,
  // dataSZ,
  // dataEPI,
  // dataLineILM,
  // dataLineSU,
  // dataLineKIT,
  // dataLineLMU,
  // dataLineRIVM,
  // dataLineRKI,
  // dataLineSZ,
  // dataLineEPI,
}) => {
  const { t, i18n } = useTranslation();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.65);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1216) {
        setChartWidth(window.innerWidth * 0.6);
      } else {
        setChartWidth(window.innerWidth * 0.65);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // let dataMethode = getDataMethoden(date);

  // let data = useDataMethodeWorker(
  //   dataMethode,
  //   menuAge,
  //   selectedScope,
  //   intervall,
  //   anzeige,
  //   "2023-07-17",
  //   date
  // );

  // let dataDatenstandBF = getDataDatenstand();

  // let dataDatenstandWorker = useDataDatenstandWorker(
  //   dataDatenstandBF,
  //   menuAge,
  //   selectedScope,
  //   anzeige,
  //   "2023-07-17",
  //   "2023-08-08"
  // );

  if (!data || !dataDatenstandWorker || !dataDatenstandSchwarzWorker) {
    return <p className="loadingNeu">Loading...</p>;
  }

  function filterData(data, methode) {
    const parseDate = timeParse("%Y-%m-%d");
    return data
      .filter((row) => row.model === methode)
      .map((row) => ({
        x: parseDate(row.target_end_date),
        y: row["quantileKlein"],
        y0: row["quantileGroß"],
      }));
  }
  function filterDataLine(data, methode) {
    const parseDate = timeParse("%Y-%m-%d");
    return data
      .filter((row) => row.model === methode)
      .map((row) => ({
        x: parseDate(row.target_end_date),
        y: row["value"],
      }));
  }

  let dataKIT;
  let dataMeanEnsemble;
  let dataLMU;
  let dataRIVM;
  let dataRKI;
  let dataSZ;
  let dataLineKIT;
  let dataLineMeanEnsemble;
  let dataLineMedianEnsemble;
  let dataLineLMU;
  let dataLineRIVM;
  let dataLineRKI;
  let dataLineSZ;
  let dataILM;
  let dataSU;
  let dataMedianEnsemble;
  let dataLineILM;
  let dataLineSU;
  let dataLineEPI;
  let dataEPI;

  let dataILMContent;
  let dataSUContent;
  let dataKITContent;
  let dataMeanEnsembleContent;
  let dataMedianEnsembleContent;
  let dataLMUContent;
  let dataRIVMContent;
  let dataRKIContent;
  let dataSZContent;
  let dataEPIContent;

  dataILM = filterData(data, "ILM-prop");
  dataSU = filterData(data, "SU-hier_bayes", intervall);
  dataKIT = filterData(data, "KIT-simple_nowcast", intervall);
  dataMeanEnsemble = filterData(data, "NowcastHub-MeanEnsemble", intervall);
  dataMedianEnsemble = filterData(data, "NowcastHub-MedianEnsemble", intervall);
  dataLMU = filterData(data, "LMU_StaBLab-GAM_nowcast", intervall);
  dataRIVM = filterData(data, "RIVM-KEW", intervall);
  dataRKI = filterData(data, "RKI-weekly_report", intervall);
  dataSZ = filterData(data, "SZ-hosp_nowcast", intervall);
  dataEPI = filterData(data, "Epiforecast", intervall);

  dataLineILM = filterDataLine(data, "ILM-prop", intervall);
  dataLineSU = filterDataLine(data, "SU-hier_bayes", intervall);
  dataLineKIT = filterDataLine(data, "KIT-simple_nowcast", intervall);
  dataLineLMU = filterDataLine(data, "LMU_StaBLab-GAM_nowcast", intervall);
  dataLineRIVM = filterDataLine(data, "RIVM-KEW", intervall);
  dataLineRKI = filterData(data, "RKI-weekly_report", intervall);
  dataLineSZ = filterDataLine(data, "SZ-hosp_nowcast", intervall);
  dataLineEPI = filterDataLine(data, "Epiforecast", intervall);

  

  if (dataMeanEnsemble.length === 0) {
    dataMeanEnsembleContent = true;
  } else {
    dataMeanEnsembleContent = false;
  }



  function filterDataDatenstand(data) {
    return data.map((row) => ({
      x: row["date"],
      y: row["valueSieben"],
    }));
  }

  let datenstandSchwarz = filterDataDatenstand(dataDatenstandWorker);
  let datenstandGrau = filterDataDatenstand(dataDatenstandSchwarzWorker);

  dataLineMeanEnsemble = filterDataLine(
    data,
    "NowcastHub-MeanEnsemble",
    intervall
  );
  dataLineMedianEnsemble = filterDataLine(
    data,
    "NowcastHub-MedianEnsemble",
    intervall
  );

  const updatedData = dataLMU.map((dataPoint) => ({
    ...dataPoint,
    y: 0,
  }));



  return (
    <div>
      {dataMeanEnsembleContent ? <p>{t("Warnmeldung_Mean")} </p> : ""}

      <div className="GraphEinbettung">
        <label className="GraphLabelLeft">{t("Hosp")}</label>
        <XYPlot
          width={chartWidth}
          height={chartWidth * 0.5}
          className="GraphExpert"
          margin={{ left: 85, bottom: 85 }}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis
            tickFormat={(d) => timeFormat("%d.%m.%Y")(d)}
            tickLabelAngle={-40}
            labelStyle={{ textAnchor: "middle" }}
          />
          <YAxis />
          {isKIT && (
            <AreaSeries
              data={dataKIT}
              color={`rgba(255,100,50, ${isKITMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isILM && (
            <AreaSeries
              data={dataILM}
              color={`rgba(0, 100, 0, ${isILMMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isSU && (
            <AreaSeries
              data={dataSU}
              color={`rgba(0, 0,100, ${isSUMarkiert ? 0.7 : 0.2})`}
            />
          )}

          {isNowcast && (
            <AreaSeries
              data={dataMeanEnsemble}
              color={`rgba(50,50,0, ${isNowcastMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isNowcastMedian && (
            <AreaSeries
              data={dataMedianEnsemble}
              color={`rgba(10,50,0, ${isNowcastMedianMarkiert ? 0.9 : 0.2})`}
            />
          )}
          {isLMU && (
            <AreaSeries
              data={dataLMU}
              color={`rgba(0, 255, 255, ${isLMUMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isRIVM && (
            <AreaSeries
              data={dataRIVM}
              color={`rgba(128,0,128, ${isRIVMMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isRKI && (
            <AreaSeries
              data={dataRKI}
              color={`rgba(100,100,100, ${isRKIMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isSZ && (
            <AreaSeries
              data={dataSZ}
              color={`rgba(75,0,130, ${isSZMarkiert ? 0.7 : 0.2})`}
            />
          )}
          {isEpiforecast && (
            <AreaSeries
              data={dataEPI}
              color={`rgba(0,0,139, ${isEpiforecastMarkiert ? 0.7 : 0.2})`}
            />
          )}

          {isKIT && (
            <LineSeries data={dataLineKIT} color="rgba(255, 100, 50, 0.5)" />
          )}
          {isSU && <LineSeries data={dataLineSU} color="rgba(0,0,100, 0.2)" />}
          {isILM && (
            <LineSeries data={dataLineILM} color="rgba(0, 100, 0, 0.5)" />
          )}
          {isNowcast && (
            <LineSeries
              data={dataLineMeanEnsemble}
              color="rgba(50,50,0, 0.5)"
            />
          )}
          {isNowcastMedian && (
            <LineSeries
              data={dataLineMedianEnsemble}
              color="rgba(10,50,0, 0.5)"
            />
          )}
          {isLMU && (
            <LineSeries data={dataLineLMU} color="rgba(0, 255, 255, 0.5)" />
          )}
          {isRIVM && (
            <LineSeries data={dataLineRIVM} color="rgba(128,0,128, 0.5)" />
          )}
          {isRKI && (
            <LineSeries data={dataLineRKI} color="rgba(100,100,100, 1)" />
          )}
          {isSZ && <LineSeries data={dataLineSZ} color="rgba(75,0,130, 0.5)" />}
          {isEpiforecast && (
            <LineSeries data={dataLineEPI} color="rgba(0,0,139, 0.2)" />
          )}
          <LineSeries data={updatedData} color="rgba(0,0,0,0)" />

          <AreaSeries data={filterData(dataSU)} color="rgba(0,0,139, 0.2)" />

          {isDatenstandSchwarz && (
            <LineSeries
              data={datenstandSchwarz}
              color="rgba(0,0,139, 0.2)"
            ></LineSeries>
          )}

          {isDatenstandGrau && (
            <LineSeries
              data={datenstandGrau}
              color="rgba(0,50,300, 0.2)"
            ></LineSeries>
          )}
        </XYPlot>

        <label className="StyleMeldedatum">{t("Meldedatum")}</label>
      </div>
    </div>
  );
};
