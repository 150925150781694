// Importing necessary dependencies and styles
import React from "react";
import "../App.css";
import "../CSS/Home.css";
import { Route, Routes, Link } from "react-router-dom";

// Importing images
import covidlogo from "../images/SARS-CoV2.png";
import fomosLogo from "../images/fomos-logo-rakete.jpeg"; // Use either "Rakete" or the text "fomos hub" in blue
import energie from "../images/Bild_energie.jpeg";

// Importing custom components
import Nav from "./Nav.js";
import Footer from "./Footer.js";

// Importing functions related to data import for graphs
import {
  setDataMethoden,
  getDataMethoden,
  setDataDatenstand,
} from "../input/Graph/DataImport/importDataMethoden.js";
import { setuseTempDataDatenstand } from "../input/Graph/DataImport/datenstandWorkerCall";
import { getDataDatenstand } from "../input/Graph/DataImport/importDataMethoden.js";
import { useDataDatenstandWorker } from "../input/Graph/DataImport/datenstandWorkerCall";
import { getDateNow } from "../input/Graph/DataImport/dateFinder";
import { setDateStart } from "../input/Graph/DataImport/dateFinder";

// Importing i18n-related dependencies
import { useTranslation } from "react-i18next";
import { use } from "i18next";


const lngs = [
  { code: "de", native: "Deutsch" },
  { code: "en", native: "English" },
];

/* Inhalt von der Fomos Main page */

function Home() {
  const { t, i18n } = useTranslation();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  // console.log(useDataGraphMethodeDirectImport());


  setDateStart();

  setDataMethoden(getDateNow());
  setDataDatenstand(getDateNow());


  return (
    <div>
      <Nav />

      {/* Einleitungstext */}
      <div>
        <section id="text">
          <div class="text-intro">
            <p>{t("text1_home")}</p>
            <p>{t("text2_home")}</p>
          </div>
        </section>

        {/* Projekte */}
        <section id="projects">
          {/* erste Reihe  */}
          <div class="card-group">
            <div class="card rounded project-box">
              <img
                src={covidlogo}
                class="card-img-top schaubild"
                alt="Schaubild Forecasting"
              />
              <div class="card-body card-project">
                <h5 class="card-title">{t("corona_headline")}</h5>
                <p class="card-text">{t("corona_explain")}</p>
                <Link to="/fomos">
                  <a
                    href="index.html"
                    class="btn btn-outline-secondary forecastingbutton"
                  >
                    {t("forecasting_button")}
                  </a>
                </Link>
              </div>
            </div>

            <div class="card rounded project-box">
              <img
                src={fomosLogo}
                class="card-img-top"
                alt="Schaubild Forecasting"
              />
              <div class="card-body">
                <h5 class="card-title">{t("two_headline")}</h5>
                <p class="card-text">{t("two_explain")}</p>
                <br></br>
                <a
                  href="#"
                  class="btn disabled btn-outline-secondary forecastingbutton"
                >
                  {t("forecasting_button")}
                </a>
              </div>
            </div>

            <div class="card rounded project-box">
              <img
                src={fomosLogo}
                class="card-img-top"
                alt="Schaubild Forecasting"
              />
              <div class="card-body">
                <h5 class="card-title">{t("three_headline")}</h5>
                <p class="card-text">{t("three_explain")}</p>
                <br></br>
                <a
                  href="#"
                  class="btn disabled btn-outline-secondary forecastingbutton"
                >
                  {t("forecasting_button")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
