
// Import necessary modules and components from React and external libraries
import React, { useEffect, useState } from "react";
import { MethodenDiv } from "../input/Graph/GraphExpert/GraphuUmgebung.js";
import "../CSS/Fomos-exp.css";
import { Route, Routes, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavFomos from "./Nav-Fomos.js";
import FooterFomos from "./Footer-Fomos.js";
import { useData } from "../input/Graph/DataImport/useDataMethode.js";
import { useDataDatenstand } from "../input/Graph/DataImport/useDataDatenstand";
import { getDateNow } from "../input/Graph/DataImport/dateFinder.js";
import { useDataMethodeWorker } from "../input/Graph/DataImport/methodenWorkerCall.js";
import { useDataDatenstandWorker } from "../input/Graph/DataImport/datenstandWorkerCall.js";
import {
  getDataMethoden,
  getDataDatenstand,
} from "../input/Graph/DataImport/importDataMethoden.js";

// Define an array of language options
const lngs = [
  { code: "de", native: "Deutsch" },
  { code: "en", native: "English" },
];

// Get the current date and the day before yesterday as strings
const today = new Date();
const yesterday = new Date(today.getTime());
yesterday.setDate(today.getDate() - 1);
const yesterdayAsString = yesterday.toISOString().substring(0, 10);

const theDayBeforeYesterday = new Date(today.getTime());
theDayBeforeYesterday.setDate(today.getDate() - 2);
const theDayBeforeYesterdayAsString = theDayBeforeYesterday
  .toISOString()
  .substring(0, 10);

// Define the Fomosexp component
function Fomosexp() {
  // Define a style object for navigation links
  const navStyle = {
    textDecoration: "none",
  };

  // Destructure the t (translation) and i18n (internationalization) objects from the useTranslation hook
  const { t, i18n } = useTranslation();

  // Function to handle language change
  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  // Get the current screen width and update it on resize
  const screenWidth = window.innerWidth;
  const [currentScreenWidth, setCurrentScreenWidth] = useState(screenWidth);

  useEffect(() => {
    // Function to handle resize events
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setCurrentScreenWidth(screenWidth);
    };

    // Add event listener for resize events
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch data using custom hooks
  const nocastArray = useData(
    "NowcastHub-MeanEnsemble",
    "00+",
    "DE",
    "FÜNFundNEUNZIG",
    "hunderttausend",
    theDayBeforeYesterdayAsString,
    yesterdayAsString
  );

  // Uncomment the line below if you want to log the nocastArray
  const unkorrHundArray = useDataDatenstand(
    "00+",
    "DE",
    "",
    "hunderttausend",
    theDayBeforeYesterdayAsString,
    yesterdayAsString
  );

  const unkorrAbsolArray = useDataDatenstand(
    "00+",
    "DE",
    "",
    "absoluteZahlen",
    theDayBeforeYesterdayAsString,
    yesterdayAsString
  );

  // Initialize variables for data values
  let nocastValueYesterday;
  let nocastValueTheDayBeforeYesterday;
  let unkorrHundValue;
  let unkorrAbsValue;

  // Check if data is still loading
  if (!nocastArray || !unkorrHundArray || !unkorrAbsolArray) {
    nocastValueYesterday = "Loading";
    nocastValueTheDayBeforeYesterday = "Loading";
    unkorrHundValue = "Loading";
    unkorrAbsValue = "Loading";
  } else {
    // Check if data arrays are not empty
    if (
      nocastArray.length !== 0 &&
      unkorrHundArray.length !== 0 &&
      unkorrAbsolArray.length !== 0
    ) {
      // Calculate rounded values for display
      nocastValueYesterday = Math.round(nocastArray[1].value * 10) / 10;
      nocastValueTheDayBeforeYesterday =
        Math.round(nocastArray[0].value * 10) / 10;
      unkorrHundValue = Math.round(unkorrHundArray[1].valueSieben * 10) / 10;
      unkorrAbsValue = Math.round(unkorrAbsolArray[1].valueSieben * 10) / 10;
    } else {
      // Display message if no data is uploaded
      nocastValueYesterday = "No Data uploaded";
      nocastValueTheDayBeforeYesterday = "No Data uploaded";
      unkorrHundValue = "No Data uploaded";
      unkorrAbsValue = "No Data uploaded";
    }
  }

  // Function to determine trend arrow based on data values
  function trendArrow() {
    if (!nocastArray || !unkorrHundArray || !unkorrAbsolArray) {
      return <p>Loading</p>;
    } else {
      if (
        nocastArray.length === 0 ||
        unkorrHundArray.length === 0 ||
        unkorrAbsolArray.length === 0
      ) {
        return <p>No Data uploaded</p>;
      } else {
        if (nocastValueYesterday > nocastValueTheDayBeforeYesterday) {
          return <p> &#8593; </p>; // Arrow up
        } else if (nocastValueYesterday < nocastValueTheDayBeforeYesterday) {
          return <p> &darr; </p>; // Arrow down
        } else if (nocastValueYesterday === nocastValueTheDayBeforeYesterday) {
          return <p> &rarr; </p>; // Right arrow
        }
      }
    }
  }


  return (
    <div>
      <NavFomos />
      <div>
        <section id="title">
          {/* Headline */}
          <div class="title">
            <i class="fa-regular fa-dash lineheader"></i>
            <div class="titel-text-main">
              <h1>NOWCAST EXPERT</h1>
              <h2> {t("heading_nowcast")}</h2>
            </div>
          </div>
        </section>

        <section id="expbutton">
          <div className="expbutton">
            {/* Expert Mode Button */}
            <Link style={navStyle} to="/fomos">
              <div class="button expbutton-expon expbutton-expon-over">
                <p class="text-expon">{t("expertbutton_aus")}</p>
              </div>
            </Link>
          </div>
        </section>

        <section id="entrance">
          {/* Buttons */}
          <div class="row rowbuttons">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <span class="hovertext" data-hover={t("button1hover_nowcast")}>
                <div class="button infobutton">
                  <p class="text-fix">{t("button1_nowcast")}</p>
                  <p class="text-var">{nocastValueYesterday}</p>
                </div>
              </span>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <span class="hovertext" data-hover={t("button2hover_nowcast")}>
                <div class="button infobutton">
                  <p class="text-fix">{t("button2_nowcast")}</p>
                  <p class="text-var"> {trendArrow()} </p>
                </div>
              </span>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 ">
              <span class="hovertext" data-hover={t("button3hover_nowcast")}>
                <div class="button infobutton">
                  <p class="text-fix">{t("button3_nowcast")}</p>
                  <p class="text-var">{unkorrHundValue}</p>
                </div>
              </span>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 ">
              <span class="hovertext" data-hover={t("button4hover_nowcast")}>
                <div class="button infobutton">
                  <p class="text-fix">{t("button4_nowcast")}</p>
                  <p class="text-var">{unkorrAbsValue}</p>
                </div>
              </span>
            </div>
          </div>
        </section>

        {/* Chart overall */}
        {/* Methode  */}

        <section id="chart">
          {/* Chart to be inserted*/}

          <MethodenDiv />
        </section>

        {/* Hinweis*/}

        <section id="hinweis">
          <div class="text-hinweis">
            <p>{t("hinweis")}</p>
          </div>
        </section>
      </div>
      <FooterFomos />
    </div>
  );
}

export default Fomosexp;
